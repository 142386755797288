import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { until } from 'protractor';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { UserService } from './user.service';


//Menu Bar
export interface Menu {
  headTitle?: string;
  title?: string;
  path?: string;
  icon?: string;
  type?: string;
  badgeClass?: string;
  badgeValue?: string;
  active?: boolean;
  module?: string,
  children?: Menu[];
  section?:number
}

@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {

  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  public megaMenu: boolean = false;
  public megaMenuCollapse: boolean = window.innerWidth < 1199 ? true : false;
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  private permissions: any;
  public nameCertification: string = this.userService.user.nameCertification;

  constructor(
    private router: Router,
    private users: UserService,
    private userService: UserService
  ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize').pipe(
      debounceTime(1000),
      takeUntil(this.unsubscriber)
    ).subscribe((evt: any) => {
      this.setScreenWidth(evt.target.innerWidth);
      if (evt.target.innerwidth < 991) {
        this.collapseSidebar = false;
        this.megaMenu = false;
      }

      if (evt.target.innerWidth < 1199) {
        this.megaMenuCollapse = true;
      }
    });
    if (window.innerWidth < 991) {
      this.router.events.subscribe(event => {
        this.collapseSidebar = false;
        this.megaMenu = false;
      })
    }

    
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getModules(){
    let MENUITEMS : Menu[] = [
      //Cursos
      { headTitle: 'Módulos', section:0},
      { path: '/home', title: 'Inicio', type: 'link', icon: 'zmdi zmdi-home', active: false, module: "home", section:0 },
      { path: '/accreditations-admin', title: `${this.nameCertification}`, type: 'link', icon: 'mdi mdi-bookmark', active: false, module: "accreditations-admin", section:0 },
      // { path: '/assistances', title: 'Asistencia', type: 'link', icon: 'mdi mdi-account-check', active: false, module: "assistances", section:0 },
      { path: '/calendar', title: 'Calendario', type: 'link', icon: 'mdi mdi-calendar', active: false, module: "calendar", section:0 },
      { headTitle: 'Catálogos', section:1},
      { path: '/change-position', title: 'Cambio de puesto', type: 'link', icon: 'mdi mdi-account-switch', active: false, module: "change-position", section:1 },
      { path: '/course-certification', title: `Cursos / ${this.nameCertification}`, type: 'link', icon: 'mdi mdi-school', active: false, module: "course-certification", section:1 },
      { path: '/employees', title: 'Empleados', type: 'link', icon: 'mdi mdi-account-multiple', active: false, module: "employees", section:1 },
      { path: '/institutions', title: 'Instituciones', type: 'link', icon: 'mdi mdi-bank', active: false, module: "institutions", section:1 },
      { path: '/reports', title: 'Reportes', type: 'link', icon: 'zmdi zmdi-assignment', active: false, module: "reports", section:1 },
      { headTitle: 'Cuenta', section:2},
      { path: '/users', title: 'Usuarios', type: 'link', icon: 'mdi mdi-account', active: false, module: "users", section:2 },
      { path: '/supports', title: 'Soporte', type: 'link', icon: 'fa fa-question-circle', active: false, module: "supports", section:2 },
    ]

    this.permissions = this.users.user.permissions;
    if (this.users.user.roleName != "ADMIN") {
      //Elimina los modulos que se tiene permisos
      MENUITEMS = MENUITEMS.filter(menu => !menu.module || this.permissions[menu.module]?.active);
      const index = MENUITEMS.findIndex(menu => menu.module);
      MENUITEMS[index].active = true;

      //Elimina los titulos que no cuentan con modulo
      let hist = {};
      MENUITEMS.map( function (a) { if (a.section in hist) hist[a.section] ++; else hist[a.section] = 1; } );
      MENUITEMS = MENUITEMS.filter(menu => hist[menu.section] > 1);
    }
    return new BehaviorSubject<Menu[]>(MENUITEMS);
  }
}
