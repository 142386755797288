<!-- <mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader> -->
<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Cambio de puesto</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
    <div class="modal-body"> 
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <h6>Empleado</h6>
                <input type="text" placeholder="Empleado" class="form-control">
            </div>
            <div class="col-sm-12 col-md-4">
                <h6>Locación</h6>
                <select class="form-control" name="" id="">
                    <option value="" selected hidden>Selecciona locación</option>
                    <option value="">Tijuana</option>
                    <option value="">Mexicali</option>
                </select>
            </div>
        </div>
        <!-- <div class="row mt-3">
            <div class="col-sm-12 col-md-6">
                <h6>Departamento</h6>
                <select name="" id="">
                    <option value="" selected hidden>Selecciona departamento</option>
                    <option value="">dpto 1</option>
                    <option value="">dpto 2</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-6">
                <h6>Locación</h6>
                <select name="" id="">
                    <option value="" selected hidden>Selecciona locación</option>
                    <option value="">Tijuana</option>
                    <option value="">Mexicali</option>
                </select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12 col-md-6">
                <h6>Nuevo puesto</h6>
                <input type="text" placeholder="Nuevo Puesto" class="form-control">
            </div>
            <div class="col-sm-12 col-md-6">
                <h6>Nuevo salario</h6>
                <input type="text" placeholder="Nuevo Salario" class="form-control">
            </div>
        </div> -->
    </div>
    <div class="_pdf">

    </div>

<div class="modal-footer justify-content-between">
    <button type="button" class="btn ripple btn-warning" (click)="close()">Cancelar</button>
    <button type="submit" class="btn ripple btn-dark" (click)="close()"><i class="fa fa-print mr-2"></i> Imprimir formato</button>
</div>