import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILogin, IMobile, IUser } from 'src/app/interfaces/iterfaces';
// import { ILogin } from 'src/app/interfaces/ilogin';
// import { IUser } from 'src/app/interfaces/iuser';
import { environment } from 'src/environments/environment';
import { CryptoSharkService } from './crypto-shark.service';
import { HttpService } from './http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenInterceptorService } from './token.interceptor.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Servicio administrador de sesión de usuario
 */
export class UserService {
  private _login: ILogin = {}
  private _user: IUser = {}
  private _employeeExam: any = {}
  public mensaje: string = '';
  
  constructor(private httpService: HttpService,
              private router: Router,
              private cryptoService: CryptoSharkService,
              private http: HttpClient) { }

  /**
   * Guardar los datos del usuario
   * @param login Credenciales del usuario que inició sesión
   */
  signInCurrentUser(login: ILogin) {
    localStorage.setItem('ldt', JSON.stringify(login));
  }

  /**
   * Obtener datos de la sesión del usuario
   */
  get login() {
    return this._login
  }

  /**
   * Obtener datos del usuario
   */
  get user() {
    return this._user
  } 

  get userEmployeeExam() {
    return this._employeeExam
  } 

  /**
   * Obtener datos de sesión del usuario validUser()
   * @param returnUrl Url a la que regresará una vez que haya iniciado sesión
   */
  getCurrentUser(returnUrl: string): Promise<boolean> {
    return new Promise((resolve) => {
      this._login = JSON.parse(localStorage.getItem(`ldt`));
      
      if (!this._login?.isRegister || localStorage.getItem('typ') != 'WEB') {
        this.router.navigate(['/login'], {queryParams: { returnUrl } });
        resolve(false);
      }
       
      environment.apiUrl = this._login.company.server;
      this.httpService.get(`api/login`).subscribe(({ key, datakey, email, name, nickname, roleName, companyName, permissions, titleCert, stepChangePosition }) => { 
        this._login.company.key = key; //<-- ENCRYPT REQUESTS 
        this._login.company.dk = (datakey && key) ? this.cryptoService.decrypt(datakey, key) : ""; //<-- DECRYPT RESPONSES

        this._user.email = email ? this.cryptoService.decrypt(email, this._login.company.key) : "";
        this._user.name = name ? this.cryptoService.decrypt(name, this._login.company.key) : "";
        this._user.nickName = nickname ? this.cryptoService.decrypt(nickname, this._login.company.key) : "";
        this._user.roleName = roleName;
        this._user.companyName = companyName ? this.cryptoService.decrypt(companyName, this._login.company.key) : ""; 
        this._user.nameCertification = titleCert || "...";
        this._user.permissions = { ...permissions };
        this._user.stepChangePosition = stepChangePosition
         
        resolve(true);
      });
    })
  }

  getCurrentUserMobile(returnUrl: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._login = JSON.parse(localStorage.getItem(`ldt`));
     
      if (!this._login || localStorage.getItem('typ') != 'MOBILE') {
        this.router.navigate(['/examen'], {queryParams: { returnUrl } });
        resolve(false);
      }
      environment.apiUrl = this._login.company.server;
      this.httpService.get(`api/login`).subscribe( async ({employee, datakey, key}: any) =>{
        this._login.company.dk = this.cryptoService.decrypt(datakey, key);
 
        let nombre = this.cryptoService.decrypt(employee.name, this._login.company.dk )
      
        this._employeeExam.employee = this.cryptoService.decrypt(employee, this._login.company.key);
        this._employeeExam.name = nombre;
        resolve(true) // Resolver promesa para dejar pasar 
         
      },( err: any )=>{ reject(true) });
      
    })
  }
  
  /**
   * Cerrar sesión
   * Borrar los datos de sesión del usuario
   */
  signOutCurrentUser() {
    // environment.apiUrl = 'http://10.10.2.34:8000/'
    localStorage.removeItem('ldt')
    this.router.navigate(['/login'])
  }

  /**
   * Cerrar sesión
   * Borrar los datos de sesión del usuario
   */
   signOutCurrentUserExam() {
    // environment.apiUrl = 'http://10.10.2.34:8000/'
    localStorage.removeItem('ldt')
    this.router.navigate(['/examen'])
  }
}
