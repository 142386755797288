import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesLoadPipe } from './images-load.pipe';
import { DecryptPipe } from './decrypt.pipe';
import { statusPipe } from './status.pipe';
import { InitialsPipe } from './initials.pipe';
import { momentPipe } from './moment.pipe';



@NgModule({
  declarations: [ImagesLoadPipe, DecryptPipe, statusPipe , InitialsPipe, momentPipe],
  imports: [],
  exports: [ImagesLoadPipe, DecryptPipe, statusPipe, InitialsPipe, momentPipe]
})
export class PipesModule { }
