import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';

interface certificateI { _id : any, title : string,  coursesData:any}
interface courseI { _id : any, title : string }
interface modalI { certificate : string, course : string }
@Component({
  selector: 'modal-create',
  templateUrl: './modal-create.component.html',
  styleUrls: ['./modal-create.component.scss']
})
export class ModalCreateComponent implements OnInit {
  @Input() options: modalI = {certificate : null, course : null};
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  public employees = [];
  public certificates:certificateI[] = [];
  public courses:courseI[] = [];
  public formAssistance: FormGroup;
  public nameCertification: string = this.userService.user.nameCertification;

  constructor(
    private fb: FormBuilder, 
    private decrypt: DecryptPipe,
    private alertService: AlertService,
    private userService: UserService,
    private cryptoService: CryptoSharkService,
    private httpService: HttpService,
  ) { }
  
  close(): void { this.onClose.emit() }

  ngOnInit(): void {
    this.formAssistance = this.fb.group({
      certificate: [null,Validators.compose([Validators.required])],
      course: [null,Validators.compose([Validators.required])],
      employees: [null,Validators.compose([Validators.required])]
    })
    this.formAssistance.get('certificate').disable();
    this.formAssistance.get('course').disable();
    this.loadCertificates();
  }

  async createAssistance(){    
    if(this.formAssistance.valid){
      this.formAssistance.value.certificate = this.options.certificate;
      this.formAssistance.value.course = this.options.course;
      let data = this.formAssistance.value;
      
      this.httpService.post('api/instructor/assistance',data).toPromise().then(response =>{
        this.alertService.successToast("Se guardo correctamente.")
        this.close();
      },error =>{
        if (error?.status != 403) {
          this.alertService.errorToast("Ocurrio un error en el servidor.");
        }
      });
    }else{
      this.alertService.infoToast("Complete todos los campos requeridos."); 
    }
  }

  async loadCertificates(){
    this.certificates = await this.httpService.get('api/instructor/certificates').toPromise();
    this.options.certificate?this.loadEmployees({_id: this.options.certificate}):"";
  }
  
  async loadEmployees(certificate: any){
    let id = null;
    this.courses = [];
    this.employees = [];

    if(certificate){
      id = certificate._id;
      this.courses = this.certificates.find(element=>{return element._id == id})?.coursesData;
      this.employees = await this.httpService.get('api/instructor/assistance/certificate/'+certificate._id).toPromise();
      this.formAssistance.patchValue({
        certificate: id,
        employees: this.employees,
        course: this.options.course,
      });
    }
    
  }

  async courseChange(course:any){
    this.formAssistance.patchValue({ course: course._id });
  }

  public dowloadExcel(){
    this.httpService.getBlob(`api/instructor/assistance/excel/${this.options.certificate}/${this.options.course}`,"Aplication/excel").subscribe((blob: any) => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = 'Reporte_de_asistencias.xlsx';
      a.click();
      URL.revokeObjectURL(objectUrl);
     });
  }

}
