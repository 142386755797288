<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Tomar asistencia</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body pb-0">
  <form [formGroup]="formAssistance">
    <div class="row">
      <div class="col-sm-6"> 
          <h6 class="">{{nameCertification}} <span class="text-danger">*</span><span *ngIf="formAssistance.controls.certificate.status == 'INVALID' " class="text-muted ml-2">Campo requerido</span></h6> 
          <ng-select class="form-control" 
            formControlName="certificate"
            [items]="certificates"
            placeholder="Selecciona {{nameCertification}}" 
            bindLabel="title"  
            bindValue="_id" 
            (change)="loadEmployees($event);">
          </ng-select>
      </div>
      <div class="col-sm-6"> 
          <h6 class="">Curso <span class="text-danger">*</span><span *ngIf="formAssistance.controls.course.status == 'INVALID' " class="text-muted ml-2">Campo requerido</span></h6> 
          <ng-select class="form-control" 
          formControlName="course"
            [items]="courses" 
            placeholder="Selecciona Curso" 
            bindLabel="title"  
            bindValue="_id" 
            (change)="courseChange($event);"> 
          </ng-select>
      </div>
    </div>
    <div class="row mt-3 py-2 border-top border-bottom">
      <div class="col-12 d-flex justify-content-between">
        <div><h4 class="m-0 text-primary">Empleados</h4></div>
        <!-- <button type="button" class="btn btn-dark btn-block col-md-auto mr-md-3 mb-3 mb-md-0" 
          (click)="dowloadExcel()"><i class="fa fa-file-download mr-2"></i> Reporte Asistencias </button> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <div class="table-responsive">
          <table class="table table-bordered table-striped mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Departameto</th>
                <th width="100px">Asistencia</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of employees">
                <td><b>{{ item.employeeNumber }}</b></td>
                <td>{{decrypt.transform(item.name) }}</td>
                <td>{{item.department}}</td>
                <td>
                  <div class="_switch cursor-pointer">
                    <label class="custom-switch d-flex justify-content-center" style="height: 22px;">
                      <span class="mr-3">NO</span>
                      <input type="checkbox" [(ngModel)]="item.assistance" [ngModelOptions]="{standalone: true}" class="custom-switch-input" />
                      <span class="custom-switch-indicator"></span>
                      <span class="ml-3">SI</span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr class="border-bottom-0">
                <td *ngIf="formAssistance.controls.employees.status == 'INVALID'" colspan="4"><div class="text-center op-7 tx-18 my-3">Seleccione una acreditación</div></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-between">   
  <button type="button" class="btn btn-warning" (click)="close();">Cancelar</button>
  <button class="btn btn-success" (click)="createAssistance();">Guardar</button>
</div>

