import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-cambio-puesto',
  templateUrl: './modal-cambio-puesto.component.html',
  styleUrls: ['./modal-cambio-puesto.component.scss']
})
export class ModalCambioPuestoComponent implements OnInit {

  @Input() data: any;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  close = () => {this.onClose.emit()}

  constructor() { }

  ngOnInit(): void {
  }

}
