import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators'; 
import { ILogin } from 'src/app/interfaces/iterfaces';
import { UserService } from '../services/user.service';
import { AlertService } from './alert.service';
import { CryptoSharkService } from './crypto-shark.service';
// import { AlertService } from '../services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  public type: string;
  constructor(
    private userService: UserService, 
    private router: Router, 
    private cryptoService: CryptoSharkService,
    private modalService: NgbModal,
    private alertService: AlertService
    ) { }
   
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next)).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log(event);
        }
        return event;
      }),
      catchError((resp: any) => {
        if (resp.status == 403) {
          // this.alertService.show('!Ups!', resp.error.message);
          this.router.navigate(['login'], {
            queryParams: { returnUrl: this.router.url },
          });
          this.modalService.dismissAll();
          this.alertService.infoToast("Su sesión a expirado!")
          this.userService.signOutCurrentUser();
        }
        return throwError(resp);
      }));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const login: ILogin = JSON.parse(localStorage.getItem('ldt'));
    const type: string = localStorage.getItem('typ') || 'WEB';
    if (login) {
      req = req.clone({
        setHeaders: {
          'token': login.token,
          "lang":"ESP"
        }
      });
    }
 
    req = req.clone({
      setHeaders: {
        'type': type,  //WEB
        "lang":"ESP",
        "app": this.cryptoService.encryptAes('lms')
      }
    });
    
 
    // const contentType = req.headers.get('Content-Type');
    if (!req.headers.has('Content-Type')) {
      // req = req.clone({
      //   setHeaders: {
      //     'Content-Type': 'application/json'
      //   },
      // });
    }

    req = req.clone({
      setHeaders: {
        'language': 'ES',
      },
    });

    req = req.clone({
      // headers: req.headers.set('Accept', 'application/json')
    });

    return next.handle(req).toPromise();
  }
}
