import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MkImgGroupComponent } from './mk-img-group/mk-img-group.component';
// import { PipesModule } from '../shared/pipes/pipes.module';
import { MkUserComponent } from './mk-user/mk-user.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MkStatusComponent } from './mk-status/mk-status.component';
import { MKLoaderComponent } from './mk-loader/mk-loader.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { statusPipe } from '../shared/pipes/status.pipe';
import { ModalCambioPuestoComponent } from './modal-cambio-puesto/modal-cambio-puesto.component';
import { CourseCardComponent } from './course-card/course-card.component';
import { DetailCourseComponent } from './detail-course/detail-course.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ModalCreateComponent } from './modal-createAsistences/modal-create.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CredentialsModalComponent } from './credentials-modal/credentials-modal.component';
import { ValidateFormsDirective } from '../directives/validate-forms.directive';

@NgModule({
  declarations: [
    MkImgGroupComponent, 
    MkUserComponent, 
    MkStatusComponent, 
    MKLoaderComponent, 
    ModalCambioPuestoComponent, 
    CourseCardComponent, 
    DetailCourseComponent, 
    ModalCreateComponent,
    CredentialsModalComponent
  ],
  exports: [
    MkImgGroupComponent, 
    MkUserComponent, 
    MkStatusComponent, 
    MKLoaderComponent, 
    ModalCambioPuestoComponent, 
    CourseCardComponent, 
    DetailCourseComponent, 
    ModalCreateComponent,
    CredentialsModalComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    NgbModule,
    SweetAlert2Module.forRoot(),
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers:[
    statusPipe,
    ValidateFormsDirective
  ]
})
export class ComponentsModule {}
