import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mk-status',
  templateUrl: './mk-status.component.html',
  styleUrls: ['./mk-status.component.scss']
})
export class MkStatusComponent implements OnInit {

  @Input() status: string;
  @Input() color: string;

  public classes = {
    APPROVED: "success",
    REJECTED: "danger",
    PENDING: "warning",
    CANCELLED: "danger",
    INPROGRESS: "warning",
    DONE: "success",
    ACCESS: "secondary",
    CAFETERIA: "secondary",
    LOST: "secondary",
    REPLACEMENT: "info",
    MOBILE: "secondary",
    TABLET: "info",
    ACTIVE: "success",
    INACTIVE: "danger",
    VISIBLE: "success",
    NOVISIBLE: "danger",
    DENIED: "danger",
    ACCEPT: "success",
    PREVENTIVE: "warning",
    NA: "warning",
    NUEVO: "warning",
    ANALIZANDO: "info",
    TRABAJANDO: "danger", 
    RESUELTO: "success",
    LIBERADO: "info",
    CHANGE_APPROVED: "success",

    ONLINE: "danger",
    INSITE: "success",
    HYBRID: "info",

    AVAILABLE: "success",
    NOT_AVAILABLE: "danger",
    SI: "success",
    NO: "danger"
  }

  constructor() { }

  ngOnInit(): void {
  }

}
 