 

<div class="page main-signin-wrapper">
  
    <ng-container *ngIf="login">
        <div class="row signpages overflow-hidden">
            <div class="col-sm-12 col-lg-5 d-none d-lg-flex details px-4">
              <div class="position-relative m-auto">
                <img class="" src="../../assets/img/brand/logoFeedbakWhite.svg" alt="">
                <span class="d-block text-white mt-3 text-center op-8">¡Bienvenido!</span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-7 login_form ">
                <form [formGroup]="loginForm">

                    <div class="card-body mt-2 mb-2">
                        <div class="row">
                            <div class="col-12"> 
                              <ngb-alert type="danger" [dismissible]="true" *ngIf="invalidCredentials">
                                <span class="alert-inner--text">Usuario y/o contraseña invalidos</span>
                              </ngb-alert>
                            </div>
                          </div>

                        <div class="row">
                          <div class="col-12">
                            <h6>Usuario</h6>
                            <input class="form-control mb-3" type="text" placeholder="Aa..."  formControlName="user"> 
                            <div *ngIf="compoIsValid('user')" class="tx-danger">
                                <small >Por favor, ingresa tú Usuario</small>
                            </div>
                            
                          </div>
                        </div>
                        
                        <div class="row">

                          <div class="col-sm-8">
                              <h6>Clave</h6>
                              <input class="form-control mb-3 mb-lg-4" type="text" placeholder="Aa..."  formControlName="code" #code>
                              <div *ngIf="compoIsValid('code')"
                                class="tx-danger">
                                <small>Por favor, ingresa tú Contraseña</small>
                              </div>
                            </div>
                            <div class="col-4">
                              <h6>Nip</h6>
                              <input class="form-control mb-3" type="password" placeholder="****"  formControlName="nip" >
                              <div *ngIf="compoIsValid('nip')" class="tx-danger">
                                <small>Por favor, ingresa tú Nip</small>
                              </div>
                            </div>

                          </div>
 
                        <div class="row">
                          <div class="col-sm-12">
                              <button class="btn ripple btn-primary btn-block" type="submit" [disabled]="this.loginForm.invalid" (click)="Login()">Iniciar Sesión</button>
                          </div>
                        </div>
                      
                    </div>

                </form>
                  
                
            </div>
    
        </div>
    </ng-container>
 
</div>
 

<img src="../../../assets/img/brand/logoFeedbakWhite.svg" alt="" class="css_logo">