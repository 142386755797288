<ng-container *ngIf="alerts.length"><div class="card">
    <div class="scroll scroll-{{index}}">
        <ng-container swiperSlide *ngFor="let alert of alerts; index as i">
            <div class="wrap {{alert.background}}">
                <div class="layer"><img src="../../../../assets/img/alerts/gifs/{{alert.layer}}.gif" alt=""></div>
                <img class="icon" src="../../../../assets/img/alerts/{{alert.icon}}.png" alt="">
                <div class="text">
                    <h5>{{alert.title}}</h5>
                    <span>{{alert.subtitle}}</span>
                </div>
                <!-- <i class="fa fa-close" (click)="close(i)"></i> -->
            </div>
        </ng-container>
    </div>
</div>
<div class="hideBtn" (click)="show = !show;">
    <i class="fa fa-chevron-down"></i>
</div>
</ng-container>