<mk-loader *ngIf="isLoading" class="loader-spinner"></mk-loader>

<div class="css_header d-flex justify-content-end">
    <div class="_avantar" *ngIf="login"> {{user.name.split(' ')[0][0]}}{{user.name.split(' ')[1][0] }}</div>
</div>
<div class="page main-signin-wrapper">
  

    <ng-container >
        <div class="card custom-card m-auto _exam">
            <!-- <p class="text-center"> </p> -->
            <div class="card-header d-flex border-bottom p-0 overflow-hidden">
                <div class="col-2 d-flex border-right bg-{{this._index > 0 ? 'primary' : 'muted'}}" (click)="prev()"><span class="m-auto">Anterior</span></div>
                <div class="col-8 d-flex flex-column">
                    <div class="m-auto w-100">
                        <div class="css_bar">
                            <div class="fill" [style.width.%]="this.getProgressPercent()"></div>
                        </div>
                        <small class="text-muted">Pregunta {{this._index + 1}} de {{this.qs?.length}}</small>
                    </div>
                </div>
                <div *ngIf="this._index < this.qs?.length - 1" class="col-2 d-flex border-left bg-{{this._index < this.qs?.length ? 'primary' : 'muted'}}" (click)="next()"><span class="m-auto">Siguiente</span></div>
                <div *ngIf="this._index == this.qs?.length - 1" class="col-2 d-flex bg-warning"><div class="m-auto" [swal]="finishSwal" (confirm)='submit()'><span class="m-auto">Terminar</span></div></div>
            </div>
            <div class="card-body pt-3"> 
                <div class="_title text-center">
                    <h3 class="mt-0 mb-4" *ngIf="this.q?.name"><span class="op-7"></span> {{this.q?.name}}</h3>
                </div>

                <div class="_options">
                    <ng-container *ngIf="q?.type == 'MULTIPLE'"> 
                        <div class="form-check p-0" *ngFor='let item of this.q?.options; let i = index' >
                            <div class="card mb-3" (click)="eventQuestion(item, this.q?.name, 'MULTIPLE')" [class.selected]="isSelected(item, q?.type)" >{{item}}</div> 
                        </div> 
                    </ng-container>

                    <ng-container *ngIf="q?.type == 'SINGLE'">
                        <div class="card mb-3"  *ngFor='let item of this.q?.options' (click)="eventQuestion(item, this.q?.name, 'SINGLE')"  [class.selected]="isSelected(item, q?.type)">{{item}} </div> 
                    </ng-container>
                    <ng-container *ngIf="q?.type == 'TEXT'">
                        <textarea placeholder="Aa..." cols="30" rows="6" 
                            #valueVariable
                            [value]="response[_index].value"
                            (change)="eventQuestion(valueVariable.value, this.q?.name, 'TEXT')"  
                        class="form-control mb-3"></textarea>
                    </ng-container>
                    <ng-container *ngIf="q?.type == 'RATING'">
                        <div class="css_stars mb-3 justify-content-between">
                            <i class="fa fa-star" *ngFor='let i of [1,2,3,4,5].reverse()' (click)="eventQuestion(i, this.q?.name, 'RATING')" [style.order]="i - 5" [class.selected]="i <= response[_index].value"></i>
                        </div>
                    </ng-container>
                    <!-- <ng-container *ngIf="this._index == this.qs?.length">
                        <div class="text-center text-primary py-3">
                            <h1 class="mt-0">Terminar examen.</h1>
                            <span class="w-100 mb-3">Presiona terminar para enviar tus respuestas</span>
                            
                        </div>
                    </ng-container> -->
                </div>
            </div>
        </div>    
    </ng-container>

</div>
<img src="../../../assets/img/brand/logoFeedbakWhite.svg" alt="" class="css_logo"> 

<!-- SWAL -->
<swal #finishSwal class="d-none" title="¿Estas seguro de terminar tu examen?" text="Al enviar tus respuestas no podras editarlas" icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}">
</swal>

