<!-- <div *ngIf="isSpinner">
    <div id="global-loader">
        <img src="../../assets/img/loader.svg" class="loader-img" alt="Loader">
    </div>
</div> -->

<app-loader></app-loader>
<router-outlet>
    <app-loader></app-loader>
</router-outlet>
<app-tap-to-top></app-tap-to-top>
<span class="badge __version">V1.0.1</span>

