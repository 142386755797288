<div class="" [class.opacity]="!item.start.active">
    <div class="title border-bottom">
        <span class="ttl">{{item.title}}</span>
        <div class="date d-flex justify-content-between">
            <span class="text-primary"><i class="fa fa-calendar mr-1 op-5"></i>Del {{item?.start?.dateStart | moment:'ll'}} al {{item?.start?.dateEnd | moment:'ll'}}</span>
            <span><i class="fa fa-circle mr-1 {{item.type | lowercase}}"></i>{{typeName[item.type]}}</span>
        </div>
    </div>
    <div class="bg-white">
        <div class="cert d-flex justify-content-between">
            <span>Duración: <b>{{item.duration}} Hrs</b></span>
            <span class="bdg" *ngIf="item?.certification?.length"><i class="fa fa-graduation-cap mr-2"></i>{{item?.certification}}</span>
            
        </div>
        <div class="desc">
            {{item.description}}
        </div>
        <div class="address text-primary" *ngIf="item?.address"><i class="fa fa-building mr-2"></i> {{item?.address}}</div>
        <div class="bot border-top">
            <div class="_user">
                <div class="img"><img [src]="img | imagesLoad" alt=""></div>
                <div class="info">
                    <b>Instructor</b>
                    <span>{{item.userData.name}}</span>
                </div>
            </div>
            <div class="_user">
                <div class="info text-right">
                    <b>Institución</b>
                    <span>{{item.institutionName}}</span>
                </div>
                <div class="img"><img [src]="imgInstitution | imagesLoad" alt=""></div>
            </div>
        </div>
    </div>
</div>