import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'status'
})

/**
 * Pipe para transformar status.
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Importar PipesModule en el módulo donde quieras tranformar status
 * 2) Utilizar el pipe 'status' en el html de tu componente
 */

export class statusPipe implements PipeTransform {

    public lenguages: ILenguege = {
        es: {
            APPROVED: "INSCRITO",
            REJECTED: "RECHAZADO",
            PENDING: "PENDIENTE",
            CANCELLED: "CANCELADO",
            INPROGRESS: "EN PROCESO",
            DONE: "COMPLETADO",
            ACCESS: "ACCESO",
            CAFETERIA: "CAFETERIA",
            LOST: "PERDIDA",
            REPLACEMENT: "REMPLAZO",
            MOBILE: "MÓVIL",
            TABLET: "TABLET",
            ACTIVE: "ACTIVO",
            INACTIVE: "INACTIVO",
            VISIBLE: "VISIBLE",
            NOVIVIBLE: "NO VISIBLE",
            DENIED: "DENEGADO",
            ACCEPT: "PERMITIDO",
            PREVENTIVE: "PREVENTIVO",
            NOT_AVAILABLE: "NO DISPONIBLE",
            AVAILABLE: "DISPONIBLE",
            ONLINE: "EN LINEA",
            INSITE: "PRESENCIAL",
            HYBRID: "HIBRIDO",
            RJOIN: "INGRESO",
            ROUT: "SALIDA",
            CHANGE_APPROVED: "CAMBIO APROBADO",

            DEPARTMENT: "DEPARTAMENTOS",
            POSITION: "PUESTOS",
            EMPLOYEE: "EMPLEADOS"
        },
        en:{
            APPROVED: "APPROVED",
            REJECTED: "REJECTED",
            PENDING: "PENDING",
            CANCELLED: "CANCELLED",
            INPROGRESS: "IN PROGRESS",
            DONE: "DONE",
            ACCESS: "ACCESS",
            CAFETERIA: "CAFETERIA",
            LOST: "LOST",
            REPLACEMENT: "REPLACEMENT",
            MOBILE: "MOBILE",
            TABLET: "TABLET",
            ACTIVE: "ACTIVE",
            INACTIVE: "ACTIVE",
            VISIBLE: "VISIBLE",
            NOVIVIBLE: "NOT VISIBLE",
            DENIED: "DENIED",
            ACCEPT: "ACCEPT",
            PREVENTIVE: "PREVENTIVE",
            NOT_AVAILABLE: "NOT AVAILABLE",
            AVAILABLE: "AVAILABLE",
            ONLINE: "ON LINE",
            INSITE: "IN SITE",
            HYBRID: "HYBRID"
        }
    }
    constructor() {}

    transform(value: string, locale: string = "es"): string {
        return value? this.lenguages[locale][value.toUpperCase()] || value :"" ;
    }

}

interface ILenguege{
    en?: any,
    es?: any,
}

