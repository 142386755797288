import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/interfaces/iterfaces';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../../shared/services/alert.service';


@Component({
  selector: 'modal-exam',
  templateUrl: './modal-exam.component.html',
  styleUrls: ['./modal-exam.component.scss']
})
export class ModalExamComponent implements OnInit {

  public isLoading: boolean = true; 
  public login : boolean = true;
  public qs: any[] = [];
  public response: any[] = [];
  public q: any;
  public _index: number = 0;
  public user: IUser = {}
  public id_curse: string = '';
  public id_certificate: string = ''; 
  public id_exam: string = '';
  public progress: string = '';
  public _id: string = '';
  private FormQuestions: FormGroup; 

  constructor(
    public userService: UserService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
  ) { }

  ngOnInit(): void { 
    this.user = this.userService.userEmployeeExam; 
    this.route.queryParams.subscribe((resp:any) => {
      this.id_curse = resp.cu;
      this.id_certificate = resp.ce; 
      this.id_exam = resp.ex;
      this._id = resp.id_join;
      
      this.httpService.get(`api/exam/start/${this.id_certificate}/${this.id_curse}/${this.id_exam}`).toPromise().then((resp:any) => {
        this.qs = resp.questions;
        this.q = this.qs[0];
        this.qs.forEach(question => {
          this.response.push({name: question.name, value: question.type == 'MULTIPLE'?[]:''});
        });
        this.isLoading = false;
      }).catch((err: any) => {
        this.isLoading = false;
      })
    });

    this.FormQuestions = this.fb.group({
      id: [this._id, Validators.required],
      course:[this.id_curse, Validators.required],
      exam: [this.id_exam, Validators.required],
      responses: [[], Validators.required],
    });
  }

  public next(){
    if (this._index < this.qs?.length) {this._index++;}
    this.getProgressPercent();
    this.q = this.qs[this._index];
  }
  
  public prev(){
    if (this._index > 0) {this._index--;}
    this.getProgressPercent();
    this.q = this.qs[this._index];
  }
  
  public getProgressPercent(){
    this.progress = (((this._index + 1) / this.qs?.length) * 100).toString();
    return this.progress;
  }


  public eventQuestion(event: any, name: string, type: string):void{
    const question = this.response.map((e) => { return e.name; }).indexOf(name);
    if (type != "MULTIPLE") {
      question >= 0? this.response[question].value = event : '';
    }else {
      const option = question >= 0?this.response[question].value?.indexOf(event): -1
      option >= 0? this.response[question].value.splice(option, 1) : this.response[question].value.push(event);
    }
  }

  public isSelected(option:string, type:string):boolean{
    let selected = false
    if (type == "MULTIPLE") {
      selected = this.response[this._index].value.indexOf(option) >= 0
    }else{
      selected = this.response[this._index].value == option;
    }
    return selected;
  }

  public submit(){ 
    try {
      this.isLoading = true;
      let isOneEmpty = false;
      for (let i = 0; i < this.response.length; i++) {
        const element = this.response[i];
        element.value.length == 0 ? isOneEmpty = true:"";
      }

      if (isOneEmpty) {
        this.isLoading = false;
        this.alertService.infoToast("Preguntas incompletas, verifique porfavor!");
      }else{
        this.FormQuestions.value.responses = this.response;
        this.httpService.post(`api/exam`,this.FormQuestions.value).subscribe(response=>{
          this.isLoading = false;
          this.alertService.successToast(response.message);
          this.router.navigate(['/web-exam']);
        },error => {
          this.isLoading = false;
          if (error?.status != 403) {
            this.alertService.errorToast(error.error.message);
          }
        })
      }
      
    } catch (error) {
      this.isLoading = false;
      this.alertService.warningToast(error.error.message);
    }
  } 
}
