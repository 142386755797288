<div class="row">
  <div class="col-12 _detail pb-3">
          <div class="card custom-card bg-primary _head mt-3">
              <div class="_bg"><img src="../../../assets/img/trianglePattern.png" alt=""></div>
              <div class="row">
                  <div class="col-12 d-flex justify-content-between flex-wrap ">
                      <div class="card border-0 _cert bg-white-9">
                        <div class="labels d-flex justify-content-between">
                            <span class="m-0">Duración: {{detail?.duration}} hrs.</span>
                            <span><i class="fa fa-circle mr-2 {{detail?.type | lowercase}}"></i> {{typeName[detail?.type]}}</span>
                        </div>
                          <div class="info">
                              <div class="title">{{detail?.title}}</div>
                              <div class="desc op-7">{{detail?.description}}</div>
                              <div class="date d-flex justify-content-between mt-3 text-primary">
                                  <b><i class="fa fa-calendar mr-2"></i>Del {{detail?.start?.dateStart | moment:'LLL'}} Al {{detail?.start?.dateEnd | moment:'LLL'}} </b>
                                  <b><i class="fa fa-building mr-2 ml-4"></i> {{detail?.address || 'Sin Lugar'}}</b>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="d-flex justify-content-between">
              <div class="user">
                  <div class="_user">
                      <div class="img"><img [src]="img | imagesLoad" alt=""></div>
                  </div>
                  <div class="info">
                      <b>Instructor</b>
                      <span>{{detail?.certificateBy.user | titlecase}}</span>
                  </div>
              </div>
              <div class="user">
                <div class="info text-right">
                    <b>Institucion</b>
                    <span>{{detail?.certificateBy.institutionName || ''}} </span>
                </div>
                  <div class="_user">
                      <div class="img"><img [src]="imgInstitution | imagesLoad" alt=""></div>
                  </div>
              </div>
          </div>
          <ng-container *ngIf="detail?.generalObjetive?.length">
              <h3 class="text-center text-primary my-3">Objetivo general</h3>
              <span class=" text-justify">{{detail?.generalObjetive}}</span>
          </ng-container>
          <ng-container *ngIf="detail?.specificObjetive?.length">
              <h3 class="text-center text-primary my-3">Objetivo específico</h3>
              <span class=" text-justify">{{detail?.specificObjetive}}</span>
          </ng-container>
          <div class="row">
              <div class="col-{{detail?.files?.length ? 6 : 12}}" *ngIf="this.options.type == 'EXAM'">
                <h3 class="text-center text-primary my-3">Ejercicios</h3>
                <div class="card custom-card px-3">
                    <div class="row" [class.border-top]="i" *ngFor="let c of detail?.index; index as i"><div class="col-12 py-2"><b><span class="text-info mr-2">{{i + 1}})</span>{{c}}</b></div></div>
                    <div class="row" *ngIf="!detail?.index?.length"><div class="col-12 text-muted py-2">Sin Ejercicios</div></div>
                </div>
              </div>
              <div class="col-6" *ngIf="this.options.type == 'EXAM' && detail?.files?.length">
                  <h3 class="text-center text-primary my-3">Archivos</h3>
                  <!-- <div class="card custom-card">
                      <div class="table-responsive">
                          <table class="table mb-0">
                              <tbody>
                                  <tr *ngFor="let file of detail.files">
                                      <td>
                                          <div class="d-flex justify-content-between">
                                              <!-- <span>{{file | json}}</span> - ->
                                              <b>{{file?.nameOriginal}}</b>
                                              <a href=""></a>
                                              <button class="btn btn-sm btn-dark btn-circle m-0" (click)="download(file?.name, file?.nameOriginal)"><i class="fa fa-download"></i></button>
                                              <!-- <a href="{{apiUrl}}{{file?.name}}" class="btn btn-sm btn-dark btn-circle m-0" download="{{file?.nameOriginal}}" target="_blank"><i class="fa fa-download"></i></a> - ->
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div> -->

                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="card custom-card mx-0 mt-0 mb-2 _file" *ngFor="let file of detail.files" (click)="download(file?.name, file?.nameOriginal)">
                        <h5 [title]="file?.nameOriginal">{{file?.nameOriginal.split('.')[0]}}</h5>
                      <div class="info">
                          <span>{{file?.nameOriginal.split('.')[1]}}</span>
                          <span><i class="fa fa-download mr-1"></i> {{file.size | bytesConvert}}</span>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <ng-container>
              <h3 *ngIf="detail?.idjoin" class="text-center text-primary my-3">Examenes</h3>
              <div *ngFor="let item of detail?.idjoin" class="card custom-card px-3 _examBtn" [swal]="startSwal" (confirm)="openExam(item.id)">
                  <div class="icon"><i class="fa fa-file-text"></i></div>
                  <div class="info">
                      <div class="center">
                          <h3>Contestar Examen</h3>
                          <span>Presiona para ir</span>
                      </div>
                  </div>
                  <div class="arrow"><i class="fa fa-chevron-right"></i></div>
              </div>
          </ng-container>
  </div>
</div>

<div class="_assist">
    <ng-container *ngIf="!hiddeReportBtn">
        <div class="card mr-3 dl" placement="top" ngbTooltip="Descargar Reporte de Asistencia" tooltipClass="tooltip-small"  (click)="dowloadExcel()">
            <i class="fa fa-download"></i>
        </div>
    </ng-container>
    <ng-container *ngIf="hiddeReportBtn">
        <div class="card mr-3 dl disabled" placement="top" ngbTooltip="Aún no hay asistencias registradas" tooltipClass="tooltip-small">
            <i class="fa fa-download"></i>
        </div>
    </ng-container>
    <div class="card" *ngIf="!hiddeTakeAssistens" (click)="openModal(modalCreate,'lg')">
        Tomar Asistencia <i class="fa fa-chevron-right ml-2"></i>
    </div>
</div>

<ng-template #modalCreate let-modal>
    <modal-create [options]="{certificate: options.certificateid, course: options.courseid}" (onClose)="modal.dismiss();"></modal-create>
</ng-template>

<swal #startSwal class="d-none" title="¿Estas seguro de iniciar tu examen?" text="Al iniciar contara como un intento." icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}">
</swal>
