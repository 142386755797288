import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DownloadServiceService } from 'src/app/shared/services/download-service.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

export interface modalI{
  type : string,
  courseid: string,
  certificateid: string,
  joinid: string
}
export interface courseI{
  _id: string,
  type: string,
  title: string,
  description: string,
  date: string,
  generalObjetive: string,
  specificObjetive: string,
  index: Array<string>,
  address : String,
  duration: string,
  certificateBy: Object,
  exam: Array<Object>,
  files: Array<Object>,
  idjoin: any,
  start: any
}
@Component({
  selector: 'detail-course',
  templateUrl: './detail-course.component.html',
  styleUrls: ['./detail-course.component.scss']
})

export class DetailCourseComponent implements OnInit {
  @Input() options: modalI;
  @Output() onCourseClose: EventEmitter<any> = new EventEmitter<any>();
  
  public detail: courseI;
  public certificateid:any;
  public apiUrl = environment.apiUrl;
  public img: string = ''; 
  public imgInstitution: string = ''; 
  public file: string = '';
  public hiddeReportBtn: boolean = false;
  public hiddeTakeAssistens: boolean = false;
  
  typeName = {
    'ONLINE': 'En linea',
    'INSITE': 'Presencial',
    'HYBRID': 'Hibrido'
  }

  constructor(
    private httpService: HttpService,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private downloads: DownloadServiceService ) { }

  ngOnInit(): void {
    console.log(this.options);
    
  }

  ngOnChanges():void{
    
    if(this.options.type == "EXAM") this.loadCourseByJoin();
    if(this.options.type == "DETAIL") this.loadCourseByID();
  }

  async loadCourseByJoin(){
    try {
      let data = await this.httpService.get('api/certificate/course/employee/'+this.options.joinid+'/'+this.options.courseid).toPromise();
      this.certificateid = data._id;
      this.detail = data.course;
      this.img = `${this.apiUrl}${data?.course?.certificateBy?.userImage}`;
      this.imgInstitution = `${this.apiUrl}${data?.course?.certificateBy?.institutionImage}`;
    } catch (error) {
      console.log(error);
      if (error?.status != 403) {
        this.alertService.error("Ocurrió un error", "No se pudo cargar el detalle del Curso", "Aceptar");
      }
    }
  }
  
  async loadCourseByID(){
    try {
      await this.httpService.get('api/course/'+this.options.certificateid+'/'+this.options.courseid).toPromise() 
      .then((resp:any) => { 
        this.detail = resp;
        this.img = `${this.apiUrl}${resp.certificateBy.userImage}`;
        this.imgInstitution = `${this.apiUrl}${resp.certificateBy?.institutionImage}`;
        this.hiddeReportBtn = !resp.assistanceExist;
        this.hiddeTakeAssistens = (moment() < moment(resp?.start?.dateStart) || moment() > moment(resp?.start?.dateEnd));
      })
    } catch (error) {
      console.log(error);
      if (error?.status != 403) {
        this.alertService.error("Ocurrió un error", "No se pudo cargar el detalle del Curso", "Aceptar");
      }
    }
  }

  async openExam(examid){
    this.router.navigate(['/answer-exam'],{ queryParams:{
       ce: this.certificateid, 
       cu: this.detail._id, 
       ex: examid, 
       id_join: this.options.joinid } });
       this.onCourseClose.emit();
  }

  toDataURL(url) {
    return fetch(url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        return URL.createObjectURL(blob);
      });
  }

  async download(file:string, nameOriginal:string) {
    //console.log(`${this.apiUrl}${file}`);
    
    // const a = document.createElement("a");
    // a.href = await this.toDataURL("https://lmsback.fdkapp.com/ut5pWAthtbJCEIdyU1HFGQ==/271d358a2b9e890e0173267bcfc1f6a2");
    // a.download = "test.jpg";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    window.open(`${this.apiUrl}${file}`, '_blank');
  }

  ___download(file:string, nameOriginal:string): void {
    this.downloads
      .download(`${this.apiUrl}${file}`)
      .subscribe(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = nameOriginal;
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
  }

  public openModal(modal, size: string = 'xxl') {  
    this.modalService.open(modal, { size: size, backdrop: 'static' });
  }

  public dowloadExcel(){
    this.httpService.getBlob(`api/instructor/assistance/excel/${this.options.certificateid}/${this.options.courseid}`,"Aplication/excel").subscribe((blob: any) => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = 'Reporte_de_asistencias.xlsx';
      a.click();
      URL.revokeObjectURL(objectUrl);
     });
  }

}
