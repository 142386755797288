import { Routes } from '@angular/router'; 
import { RoleGuard } from '../services/role-guard.service';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
export const Pages_Routes: Routes = [
    {
        path: '',
        canActivate: [RoleGuard],
        loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'course-certification',
        canActivate: [RoleGuard],
        data: { role: 'course-certification'},
        loadChildren: () => import('../../pages/courses/courses.module').then(m => m.CoursesModule)
    }, 
    {
        path: 'change-position',
        canActivate: [RoleGuard],
        data: { role: 'course-certification'},
        loadChildren: () => import('../../pages/change-position/change-position.module').then(m => m.ChangePositionModule)  
    },
    {
        path: 'home',
        canActivate: [RoleGuard],
        data: { role: 'home'},
        loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'users',
        canActivate: [RoleGuard],
        data: { role: 'users'},
        loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'accreditations-admin',
        canActivate: [RoleGuard],
        data: { role: 'accreditations-admin'},
        loadChildren: () => import('../../pages/certification-admin/certification-admin.module').then(m => m.CertificationAdminModule)
    },
    {
      path: 'assistances',
      canActivate: [RoleGuard],
      data: { role: 'assistances'},
      loadChildren: () => import('../../pages/assistances/assistances.module').then(m => m.AssistancesModule)
    },
    {
        path: 'calendar',
        canActivate: [RoleGuard],
        data: { role: 'calendar'},
        loadChildren: () => import('../../pages/calendar-instructor/calendar-instructor.module').then(m => m.calendarInstructorModule)
    },
    {
        path: 'employees',
        canActivate: [RoleGuard],
        data: { role: 'employees'},
        loadChildren: () => import('../../pages/employees/employees.module').then(m => m.EmployeesModule)
    },
    {
        path: 'institutions',
        canActivate: [RoleGuard],
        data: { role: 'institutions'},
        loadChildren: () => import('../../pages/institutions/institutions.module').then(m => m.InstitutionsModule)
    },
    {
        path: 'reports',
        canActivate: [RoleGuard],
        data: { role: 'reports'},
        loadChildren: () => import('../../pages/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'supports',
        canActivate: [RoleGuard],
        data: { role: 'supports'},
        loadChildren: () => import('../../pages/supports/supports.module').then(m => m.SupportsModule)
    } 
                                  
]