

<!-- <div class="css_header d-flex justify-content-end">
     
    <div class="_avantar main-notification-title" *ngIf="login"> {{user.name.split(' ')[0][0]}}{{user.name.split(' ')[1][0] }}</div> 
</div> -->
<div ngbDropdown class="css_header d-flex justify-content-end">
    <a ngbDropdownToggle class="d-flex align-items-center" href="javascript:void(0)">
        <span class="main-img-user" >{{user.name.split(' ')[0][0]}}{{user.name.split(' ')[1][0] }}</span>
    </a>
    <div ngbDropdownMenu>
        <div class="header-navheading">
            <h6 class="main-notification-title">{{user.name}}</h6>
            <p class="main-notification-text">{{user.email}}</p>
        </div>
        <button type="button" ngbDropdownItem class="" (click)="signOutHandler()">
            <i class="fe fe-power"></i> Sign Out
        </button>
    </div>
</div>
<div class="page main-signin-wrapper">
  <!-- <div class="row d-flex justify-content-center">
    <div class="col-sm-6">
      <div class="card custom-card"> 
        <div class="card-body"> 
          <div class="d-flex justify-content-between mb-3 border-bottom">
            <h3>Kevin Eduardo Cerda Salazar</h3>
            <div class="d-sm-flex">
              <div class="mg-sm-r-20 mg-b-10">
                <div class="main-profile-contact-list">
                  <div class="media">
                    <div class="media-icon bg-primary-transparent text-primary">
                      10
                    </div>
                    <div class="media-body">
                      <span> 2</span> 
                      <div>Terminados</div> 
                    </div> 
                  </div> 
                </div>
               </div>
            </div>
          </div>
          <div>
            <h6 class="main-content-label mb-1">Examenes pendientes de realizar</h6> 
            <p class="text-muted card-sub-title text-danger">Recuerda que iniciar un examen contara como un intento !</p>
          </div> 
          <div class="table-responsive">
            <table class="table text-nowrap text-md-nowrap table-bordered mg-b-0 border"> 
                <thead>
                  <tr>
                    <th>Certificado</th>
                    <th>Curso</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let exam of exams">
                    <td>{{exam.name_certificate}}</td>
                    <td>{{exam.name_certificate}}</td>
                    <td>
                      <button class="btn btn-block ripple btn-primary" 
                        [swal]="startSwal" 
                        (confirm)='loadExamn({ce: exam.id_certificate, cu:exam.id_course, ex: exam.id_exam, id_join:exam.id_join})'>
                        comenzar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> 
          </div> 
        </div>
    </div>
  </div> -->

  <div class="scroller">
    <ng-container *ngIf="exams?.length > 0 else noExam" >
        <div class="row d-flex justify-content-center" *ngFor="let exam of exams">
            <div class="col-sm-12 col-lg-6" >
                <div class="card custom-card overflow-hidden">
                    <div class="card-body _exm">
                        <div class="row ">
                            <div class="col-8">
                              <span class="mb-0 text-muted">Curso</span>
                                <h2>{{ exam.name_course}}</h2>
                                <h5 class="m-0"><b class="text-primary">Acreditación:</b> {{exam.name_certificate}}</h5>
                            </div>
                            <div class="col-4 d-flex flex-column justify-content-end">
                              <h4 class="text-right mb-2"><span class="text-warning">Intentos:</span> {{ exam.exam_complete || 0}}/ {{ exam.exam_opp || 0}}</h4>
                              <span class="text-right tx-12 text-muted mb-2">Cada intento es considerado.</span>
                                <div>
                                    <button class="btn ripple btn-block btn-primary" [swal]="startSwal" (confirm)='loadExamn({ce: exam.id_certificate, cu:exam.id_course, ex: exam.id_exam, id_join:exam.id_join})'><h4 class="my-0">Comenzar <i class="fa fa-chevron-right ml-1"></i></h4></button>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
        </div>
    </ng-container> 
  </div>


</div>
<img src="../../../assets/img/brand/logoFeedbakWhite.svg" alt="" class="css_logo">


<ng-template #noExam>
    <div class="row d-flex justify-content-center">
        <div class="col-sm-12 col-md-10 col-lg-7" >
            <div class="card custom-card">
                <div class="card-body _exm">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="m-0 text-muted">Sin examen disponible</h3>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</ng-template> 
    
<!-- SWAL -->
<swal #startSwal class="d-none" title="¿Estas seguro de iniciar tu examen?" text="Al iniciar contara como primer intento." icon="question" [showCancelButton]="true" [focusCancel]="true" [swalOptions]="{confirmButtonText: 'Aceptar', cancelButtonText: 'Cancelar'}">
</swal>


 