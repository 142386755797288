import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ExamenGuard implements CanActivate {

  constructor(private userService: UserService) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
    return this.userService.getCurrentUserMobile(state.url)
  }

  canLoad(): Observable<boolean>  | boolean{
    console.log('CanLoad');
    return true;
  }
}
